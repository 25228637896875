<template>

  <section class="register-form-wrapper p-4">

    <h3 class="text-light text-center mt-1 mb-3"> <span class="text-yellow">My Profile</span> </h3>

    <div class="form-wrapper mb-3">

      <div class="mb-3">
        <input
          style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
          type="text" class="form-control" placeholder="Full Name" aria-label="Full name" v-model="fullname">
      </div>

      <div class="mb-3">
        <input
          style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
          type="email" class="form-control" placeholder="Email" aria-label="Email" v-model="email">
      </div>

      <div class="mb-3">
        <input
          style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
          type="text" class="form-control" placeholder="Phone Number" aria-label="Phone number" v-model="msisdn"
          disabled>
      </div>

      <div class="mb-0">
        <input
          style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
          type="text" class="form-control" placeholder="Male/Female" aria-label="Gender" v-model="gender">
      </div>

      <div class="text-center mb-2 mt-4">
        <button @click="updateProfile" class="join-button py-2 form-control">Update Profile</button>
      </div>

    </div>

    <h3 class="text-light text-center mt-1 mb-3"> <span class="text-yellow">Security Settings</span> </h3>

    <div class="form-wrapper mb-3">

      <div class="text-yellow text-center mb-2"> Change your login PIN/Password</div>

      <div class="mb-3">
        <input
          style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
          type="password" class="form-control" placeholder="Current Password" aria-label="Current Password"
          v-model="password">
      </div>

      <div class="mb-3">
        <input
          style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
          type="password" class="form-control" placeholder="New Password" aria-label="New Password" v-model="password1">
      </div>

      <div class="mb-3">
        <input
          style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
          type="password" class="form-control" placeholder="Repeat Password" aria-label="Repeat Password"
          v-model="password2">
      </div>

      <div class="text-center mb-2 mt-4">
        <button @click="changePassword" class="join-button py-2 form-control">Change Password</button>
      </div>

    </div>

    <hr class="m-1">
    <a class="custom-link" @click="goTo('faq')">
      <div class="help-text-line text-blue">
        <img src="/img/profile/email_blue.svg" class="input-group-text-icon">
        Get help from our customer service channels
      </div>
    </a>

    <a class="custom-link" href="">
      <div class="help-text-line text-blue">
        <img src="/img/profile/email_blue.svg" class="input-group-text-icon">
        Report a problem
      </div>
    </a>

    <a class="custom-link" href="#">
      <div class="help-text-line text-blue">
        <img src="/img/profile/email_blue.svg" class="input-group-text-icon">
        Legal - Terms and conditions and policies
      </div>
    </a>

    <hr class="m-1">
    <div class="text-center slip-ttxt py-3">
      <div class="text-blue mb-3">Want to logout? We'd like you to stay though</div>
      <div>
        <a @click="logout" class="login-button py-2 px-4 ">Logout</a>
      </div>
    </div>

  </section>

</template>

<style scoped>
.input-signup,
.input-login {
  border: 1px solid #ccc;
  border-radius: .5em;
  height: 3em;
}
</style>

<script>
import axios from "@/services/api";

export default {
  name: 'Profile',
  components: {
  },
  comments: {

  },
  data: function () {

    return {
      msisdn: '',
      fullname: '',
      email: '',
      gender: '',
      password: '',
      password1: '',
      password2: '',
      error: [],
      warning: [],
      success: [],
      loading: '',
      action_login: true,
      action_signup: false,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: false,
      code: '',
      placeBet: 0,
      type: 'password',
      btnText: '/img/other/eye.svg'
    }
  },
  methods: {

    login: function () {

      this.reset();
      this.removeAuth();

      if (this.msisdn.length < 9) {

        this.$toast.open({
          message: 'Please enter a valid mobile number',
          type: 'error',
          position: 'top'
        });
        return;

      }

      if (this.password.length < 4) {

        this.$toast.open({
          message: 'Please enter a password of more than 4 characters',
          type: 'error',
          position: 'top'
        });
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_LOGIN;

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
       

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: document.referrer
      }))
        .then(res => {

          vm.loading = '';
          var profile = res.data.message;
          var status = res.data.status;

          if (parseInt(status) === 201) {

            // take password verification code
            vm.setVerifyAccount();
            return;

          }

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess("Success", "Login successful");
          // go to previous page
          console.log('Inititalize MQTT');
          vm.EventBus.$emit('init:mqtt');

          if (parseInt(vm.placeBet) === 1) {

            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:betslip:show');

          } else if (parseInt(vm.placeBet) === 2) {

            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:outrightbetslip:show');

          } else {

            vm.goBack();

          }

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });


            if (parseInt(err.response.status) === 428) {

              vm.setVerifyAccount();
              return;
            }
          }
          else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          }
          else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }

        })

    },
    updateProfile: function () {

      this.reset();

      if (this.fullname.length < 4) {

        this.$toast.open({
          message: 'Please enter a valid fullname',
          type: 'error',
          position: 'top'
        });
        return;
      }

      /*
      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_SIGNUP;

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
       

      axios.post(path, JSON.stringify({
        fullname: vm.fullname,
        gender: vm.gender,
        email: vm.email,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: document.referrer
      }))
          .then(res => {

            vm.loading = '';
            console.log(JSON.stringify(res));
            var msg = res.data.message;
            vm.setSuccess("Signup successful", msg);
            // go to password verification page
            vm.setVerifyAccount();

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            }
            else if (err.request) {

              this.$toast.open({
                message: 'Check your network connection and try again',
                type: 'error',
                position: 'top'
              });
              console.log(JSON.stringify(err.request));

            }
            else {

              this.$toast.open({
                message: 'Check your network connection and try again',
                type: 'error',
                position: 'top'
              });
              console.log(JSON.stringify(err));

            }

          })
      */
    },
    getResetCode: function () {

      this.reset();

      if (this.msisdn.length < 9) {

        this.$toast.open({
          message: 'Please enter a valid mobile number',
          type: 'error',
          position: 'top'
        });
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_PASSWORD_RESET;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn)
      }))
        .then(res => {

          vm.loading = '';
          console.log(JSON.stringify(res));

          vm.setSuccess("Success", "Password reset code has been send to your phone");

          // go to password reset page
          vm.setChangePassword();

        })
        .catch(err => {

          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          }
          else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }

        })

    },
    changePassword: function () {

      this.reset();

      if (this.password.length < 0) {
        this.$toast.open({
          message: 'Please enter current password',
          type: 'error',
          position: 'top'
        });
        return;

      }

      if (this.password1.length < 4) {

        this.$toast.open({
          message: 'Please enter a password of more than 4 characters',
          type: 'error',
          position: 'top'
        });
        return;

      }

      if (this.password2 !== this.password1) {

        this.$toast.open({
          message: 'Your passwords does not match',
          type: 'error',
          position: 'top'
        });
        return;

      }

      if (this.code.length < 4) {

        this.$toast.open({
          message: 'Please enter a valid verification code',
          type: 'error',
          position: 'top'
        });
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_PASSWORD_CHANGE;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
        old_password: this.code
      }))
        .then(res => {

          console.log(JSON.stringify(res));

          vm.loading = '';
          vm.setSuccess("Password Changed", "Your password has been changed successfully,. Please login to continue");

          // go to login page
          vm.setLogin();

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          }
          else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }

        })

    },
    verifyAccount: function () {

      this.reset();

      if (this.code.length < 4) {

        this.$toast.open({
          message: 'Please enter a valid verification code',
          type: 'error',
          position: 'top'
        });
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_VERIFY;
      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
       

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        code: this.code,
        login_tag: login_tag,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: document.referrer
      }))
        .then(res => {

          vm.loading = '';
          var profile = res.data.message;
          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess("Congratulations!!", "Your account has been verified successfully");
          vm.EventBus.$emit('init:mqtt');

          if (parseInt(vm.placeBet) === 1) {

            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:betslip:show');

          } else {

            vm.goHome();

          }

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          }
          else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }

        })

    },
    setSignup: function () {

      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setLogin: function () {

      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setGetResetCode: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;

    },
    setChangePassword: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setVerifyAccount: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;

    },
    showPassword: function () {
      if (this.type === 'password') {
        this.type = 'text'
        this.btnText = '/img/other/eyeslash.svg'
      } else {
        this.type = 'password'
        this.btnText = '/img/other/eye.svg'
      }
    }
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "profile");

  },
  computed: {
    profile: function () {
      return this.getProfile();
    },
  }
}
</script>